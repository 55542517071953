// import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';

export function TrackingDialog(props) {
    const { onClose, selectedValue, open,packageString,time } = props;
    console.log(packageString);
    // let timeFormat = moment(time).format('YYYY-MM-DD');
    // const getTime = time.slice(0, 12)
    // console.log(getTime)
    const handleClose = () => {
        onClose(selectedValue);
    };
    const formateDate = (unixts)=>{
        var date = new Date(unixts);
        var fdate = date.getFullYear() + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + ("0" + date.getDate()).slice(-2);
        return fdate
    }

    return (
        <Dialog onClose={handleClose} open={open}><br/><br/>
        <div style={{overflowX:"hidden"}}>
            <ul class="events">
            {packageString?.map(function(object, i){
                console.log(new Date(object.timestamp))
            return <>
                <li>
                      <time datetime={new Date(object.timestamp)} className='is-done'>{formateDate(object.timestamp)}</time>
                      {" "}
                      <br/>
                    
                      <span className='is-line-done'>{object.status_code_string}</span></li>
  
                  <li></li>
                 </>
             })}
            
              

            </ul>
            </div>
        </Dialog>
    );
}