import React, { useCallback, useState } from "react";

import {
    useIndexResourceState,
    Card,
    IndexTable,
    TextStyle,
    SkeletonBodyText,
    Button,
    Tag,
    Filters,
    Pagination,
} from "@shopify/polaris";
import { API_ROOT } from "../constant/config.js"


import axios from "axios";
import { TrackingDialog } from "./TrackingDialog";
import { LoadingPrintBox, NoRecordsFound } from "./LoadingBox";
import Fuse from "fuse.js";


export default function BookedOrder() {
    const [orders, setOrders] = useState([]);
    const [packageString, setPackageString] = useState(null);
    const [time, setTime] = useState(null);
    const [interSection, setInterSection] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [printLoading, setPrintLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [queryData, setQuerydata] = useState([]);
    const [page, setPage] = useState(1)
    const [limit] = useState(10)


    // get shop
    const shop = localStorage.getItem("seperatedShop");

    const handleFiltersQueryChange = useCallback(
        (value) => setQueryValue(value),
        [],
    );

    const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
    // get location id
    function getLocation(id) {
        const location = locations.filter((item) => {
            return String(item.id) === String(id);
        })[0];

        return location?.name;
    }


    React.useEffect(() => {
        try {
            setLoading(true);
            async function getOrders(shop) {
                // get orders
                const orderss = await axios.get(`${API_ROOT}/orders?shop=${shop}&isBooked=true`);
                setOrders(orderss.data.data.docs);
                setLoading(false);
                const filteredData = localStorage.getItem("booking");
                // get data based on shopify order id
                let intersection = orderss.data.data.filter(x => filteredData.includes(x._id));
                setInterSection(intersection);
            }
            async function getLocations() {
                const shop = localStorage.getItem("seperatedShop");
                const locations = await axios.get(`${API_ROOT}/locations?shop=${shop}`);
                setLocations(locations.data.data);
            }
            getLocations();
            getOrders(shop);

        } catch (e) {
            console.log(e.response && e.response.message)

        }

    }, [shop, page, limit]);


    const resourceName = {
        singular: "order",
        plural: "orders",
    };

    // get tracking data history
    const viewHistory = async (TrackingId) => {
        try {
            setOpen(true);
            const response = await axios.get(`${API_ROOT}/track?shop=${shop}&TrackingId=${TrackingId}`);
            setPackageString(response.data.data.status_history);
        }
        catch (e) {
            console.log(e.response && e.response.message)
        }

    };
    const handleClose = (value) => {
        setOpen(false);
    };

    //print pdf
    const handlePrint = async (orderId, trackId,id) => {
        try {
            setPrintLoading(true)
            const printResponse = await axios.get(`${API_ROOT}/print-lable?shop=${shop}&orderId=${orderId}&trackId=${trackId}&id=${id}`);
            setPrintLoading(false)
            window.open(printResponse.data.data.url, "_blank")

        } catch (e) {
            console.log(e.response && e.response.message)
        }

    };

    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(orders);

    const fuse = new Fuse(orders,
        {
            keys: [
                'OrderId',
                'customerName',
            ]
        })

    const results = fuse.search(queryValue);
    const ordersResult = queryValue ? results.map((r) => r.item) : orders;
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    const rowMarkup =
        ordersResult &&
        ordersResult.map(
            (
                {
                    _id,
                    customerEmail,
                    customerName,
                    TotalAmount,
                    OrderCreatedAt,
                    OrderId,
                    isEligable,
                    BookingId,
                    TrackingId,
                    TrackingStatus,
                    LocationId,
                    isBooked
                },
                index
            ) => (
                <IndexTable.Row
                    id={_id}
                    key={_id}
                    selected={selectedResources.includes(_id)}
                    position={index}>
                    <IndexTable.Cell>
                        <TextStyle variation='strong'>{OrderId}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <TextStyle>
                            {getLocation(LocationId)}
                        </TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <TextStyle>{isEligable ? "Eligible" : "Non Eligible"}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{OrderCreatedAt.split("T")[0]}</IndexTable.Cell>
                    <IndexTable.Cell>{customerName}</IndexTable.Cell>
                    <IndexTable.Cell>{customerEmail}</IndexTable.Cell>
                    <IndexTable.Cell>{formatter.format(TotalAmount)}</IndexTable.Cell>
                    <IndexTable.Cell>
                        {isBooked ? <span>Booked</span> : <span>Pending</span>}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <>
                            {BookingId ? <><Tag>{BookingId}</Tag></> : null}
                        </>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                        {" "}
                        <>
                            {TrackingId ? <><Tag>{TrackingId}</Tag></> : null}
                        </>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <>
                            {TrackingStatus ? <><Tag>{TrackingStatus}</Tag></> : null}
                        </>
                    </IndexTable.Cell>


                    {isBooked ? (
                        <IndexTable.Cell>
                            <div data-primary-link>
                                <Button
                                    // loading={printLoading}
                                    onClick={() => handlePrint(OrderId, TrackingId,_id)}>
                                    Lable
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button primary onClick={() => viewHistory(TrackingId)}>
                                    Track
                                </Button>
                            </div>
                        </IndexTable.Cell>
                    ) : (<IndexTable.Cell>
                        <div data-primary-link>
                        </div>
                    </IndexTable.Cell>)}
                </IndexTable.Row>
            )
        );

    return (
        <Card sectioned>
            <div style={{ marginBottom: "20px" }}>
                <Filters
                    queryValue={queryValue}
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={handleFiltersQueryChange}
                    onQueryClear={handleQueryValueRemove}
                /></div>
            {printLoading && <LoadingPrintBox />}
            {!loading ? <>
                <TrackingDialog
                    large
                    open={open}
                    time={time}
                    packageString={packageString}
                    onClose={handleClose}
                />
                {rowMarkup.length === 0 ?
                    <NoRecordsFound />

                    : <><IndexTable
                        resourceName={resourceName}
                        itemCount={orders && orders.length}
                        selectedItemsCount={
                            allResourcesSelected ? "All" : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        selectable={false}
                        headings={[
                            { title: "Shopify Order Id" },
                            { title: "Origin" },
                            { title: "Order Eligibility" },
                            { title: "Shopify Order Date" },
                            { title: "Customer Name" },
                            { title: "Customer Email" },
                            { title: "Order Amount" },
                            { title: "Booking Status" },
                            { title: "Booking Id" },
                            { title: "Tracking Id" },
                            { title: "Tracking Status" },
                            { title: "Print/Tracking" },

                        ]} lastColumnSticky>
                        {rowMarkup}
                    </IndexTable>

                    </>
                }
                <div style={{ marginTop: "20px" }}>
                    <Pagination
                        hasPrevious={queryData.hasPrevPage}
                        onPrevious={async () => {
                            setPage(page - 1)
                        }}
                        hasNext={queryData.hasNextPage}
                        onNext={async () => {
                            setPage(page + 1)
                        }}
                    />
                </div>

            </> : <>

                <SkeletonBodyText />
                <SkeletonBodyText />
            </>}
        </Card>
    );
}