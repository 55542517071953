import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

import BookingPage from "./components/BookingPage";
import Settings from "./settings";

function BaseRoute() {
  const shop = localStorage.getItem("seperatedShop");
  // const splitShop = shop.split("/");

  // console.log(splitShop,"shop")
  console.log(`/?shop=${shop}/booking`, "paathh");

  return (
    <>
      <Routes>
        <Route path='/' element={<Settings />} />
        <Route path={`/booking`} element={<BookingPage />} />

        {/* <Route path="booking" element={<BookingPage />} /> */}
      </Routes>
    </>
  );
}

export default BaseRoute;
