import { Card, Frame, Page, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

export default function Skeletons() {
    return (
        <div>
         <Frame><Page fullWidth>
         <Card >
             <div style={{ padding: "30px" }}>
            <div style={{ paddingBottom: "30px" }}><SkeletonBodyText /><SkeletonBodyText /></div>
            <div style={{ paddingBottom: "30px" }}><SkeletonBodyText /><SkeletonBodyText /></div>
            <div style={{ paddingBottom: "30px" }}><SkeletonBodyText /><SkeletonBodyText /></div>
            </div>
        </Card>
        </Page></Frame></div>
    )
}
