import React from 'react';
import { Page, AppProvider, Button } from '@shopify/polaris';
import { useEffect } from 'react';
import UnAuthorizePage from './components/UnAuthorizePage';
import { Provider } from '@shopify/app-bridge-react'
import BaseRoute from './BaseRoute';
import { BrowserRouter } from 'react-router-dom';


function App() {
  const [shopName, setShopName] = React.useState('');
  const [host, setHost] = React.useState('');
  const [noAccess, setNoAccess] = React.useState(false);


  useEffect(() => {
    // check shop name in QueryString
    const shopName = window.location.search.split('=')[1];
    if(!shopName) {
      setNoAccess(true);
      return;
    }
    // check host in QueryString


    const path = window.location.href.replace(/^.+\?/i, '');
    const searchParams: URLSearchParams = new URLSearchParams(path);
    const store: string = searchParams.get('shop')!;
    const host: string = searchParams.get('host')!;
    const splitShop = shopName.split("/");
    const seperatedShop = splitShop[0];
    if (!seperatedShop) {
      setNoAccess(true)
    }
    setShopName(seperatedShop);
    setHost(host);
    localStorage.setItem('seperatedShop', seperatedShop);

    

  }, []);
  console.log(window.btoa(`${shopName}/admin`),"shopName");
  const config = {
    apiKey: "c7de61ee38c52e4e79141a24106bf857",
    host: "aW50ZWxjb20tZXhwcmVzcy5teXNob3BpZnkuY29tL2FkbWlu",
    forceRedirect: process.env.NODE_ENV === 'production',
  };
  return (
    <>
    
    <BrowserRouter>
      <AppProvider
        i18n={{
          Polaris: {
            'App.name': 'It\'s running! 🤩',
            'App.description': 'Fetched from store: {shopName}',
          },
        }}
      >
        <Provider config={config}>
          {noAccess ? <UnAuthorizePage /> : (
            <Page fullWidth>
              <BaseRoute/>
            </Page>
          )} 
          
        </Provider>

      </AppProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
