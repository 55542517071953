import React, { useState, useCallback } from "react";

import {
    useIndexResourceState,
    Card,
    IndexTable,
    TextStyle,
    SkeletonBodyText,
    Filters,
    Pagination,
} from "@shopify/polaris";
import { API_ROOT } from "../constant/config.js"
import Fuse from "fuse.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NoRecordsFound } from "./LoadingBox.js";

export default function NonEligibleOrder() {
    const history = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [queryValue, setQueryValue] = useState('');
    const [locations, setLocations] = useState([]);
    const [queryData, setQuerydata] = useState([]);
    const [page, setPage] = useState(1)
    const [limit] = useState(10)

    console.log(queryValue)

    // get shop
    const shop = localStorage.getItem("seperatedShop");
    function getLocation(id) {
        const location = locations.filter((item) => {
            return String(item.id) === String(id);
        })[0];

        return location?.name;
    }
    // get orders
    React.useEffect(() => {
        try {
            setLoading(true);
            async function getOrders(shop) {
                const orders = await axios.get(`${API_ROOT}/orders?shop=${shop}&page=${page}&limit=${limit}`);
                setOrders(orders.data.data.docs);
                setQuerydata(orders.data.data)
                setLoading(false);
            }
            async function getLocations() {
                const shop = localStorage.getItem("seperatedShop");
                const locations = await axios.get(`${API_ROOT}/locations?shop=${shop}`);
                setLocations(locations.data.data);
            }
            getLocations();
            getOrders(shop);
        }
        catch (e) {
            console.log(e.response && e.response.message)
        }

    }, [shop, page, limit]);

    // get nonEligibale data

    let noneEligableData =
        orders &&
        orders.filter(function (e) {
            return e.isEligable === false;
        });

    const fuse = new Fuse(noneEligableData,
        {
            keys: [
                'OrderId',
                'customerName',
            ]
        })

    const results = fuse.search(queryValue);
    const ordersResult = queryValue ? results.map((r) => r.item) : noneEligableData;

    const resourceName = {
        singular: "order",
        plural: "orders",
    };
    const bulkActions = [
        {
            content: "Print booking",
            onAction: () => { },
        },
    ];

    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(orders);

    const promotedBulkActions = [
        {
            content: "Create Booking",
            onAction: () => {
                const myShopName = localStorage.getItem("seperatedShop");
                history(`/?shop=${myShopName}/booking`);
            },
        },
    ];

    const handleFiltersQueryChange = useCallback(
        (value) => setQueryValue(value),
        [],
    );
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

    const rowMarkup =
        ordersResult &&
        ordersResult.map(
            (
                {
                    _id,
                    customerEmail,
                    customerName,
                    TotalAmount,
                    OrderCreatedAt,
                    OrderId,
                    isEligable,
                    BookingId,
                    TrackingId,
                    TrackingStatus,
                    LocationId,
                    errorMessage,
                    isBooked
                },
                index
            ) => (
                <IndexTable.Row
                    id={_id}
                    key={_id}
                    selected={selectedResources.includes(_id)}
                    position={index}>
                    <IndexTable.Cell>
                        <TextStyle variation='strong'>{OrderId}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <TextStyle>
                            {getLocation(LocationId)}
                        </TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <TextStyle>{isEligable ? "Eligible" : "Non Eligible"}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{OrderCreatedAt.split("T")[0]}</IndexTable.Cell>
                    <IndexTable.Cell>{customerName}</IndexTable.Cell>
                    <IndexTable.Cell>{customerEmail}</IndexTable.Cell>
                    <IndexTable.Cell>{formatter.format(TotalAmount)}</IndexTable.Cell>
                    <IndexTable.Cell>{errorMessage}</IndexTable.Cell>

                    <IndexTable.Cell>
                        {isBooked ? <span>Booked</span> : <span>Pending</span>}
                    </IndexTable.Cell>
                </IndexTable.Row>
            )
        );

    return (
        <Card sectioned>
            <div style={{ marginBottom: "20px" }}>
                <Filters
                    queryValue={queryValue}
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={handleFiltersQueryChange}
                    onQueryClear={handleQueryValueRemove}
                /></div>

            {!loading ? <>
                {rowMarkup.length === 0 ?
                    <NoRecordsFound />
                    : <><IndexTable
                        resourceName={resourceName}
                        itemCount={orders && orders.length}
                        selectedItemsCount={
                            allResourcesSelected ? "All" : selectedResources.length
                        }
                        bulkActions={bulkActions}
                        promotedBulkActions={promotedBulkActions}
                        onSelectionChange={handleSelectionChange}
                        selectable={false}
                        headings={[
                            { title: "Shopify Order Id" },
                            { title: "Origin" },
                            { title: "Order Eligibility" },
                            { title: "Shopify Order Date" },
                            { title: "Customer Name" },
                            { title: "Customer Email" },
                            { title: "Order Amount" },
                            { title: "Error Message" },
                            { title: "Booking Status" }
                            ,
                        ]}>
                        {rowMarkup}
                    </IndexTable>
                       </>
                }
                <div style={{ marginTop: "20px" }}>
                    <Pagination
                        hasPrevious={queryData.hasPrevPage}
                        onPrevious={async () => {
                            setPage(page - 1)
                        }}
                        hasNext={queryData.hasNextPage}
                        onNext={async () => {
                            setPage(page + 1)
                        }}
                    />
                </div>
            </> : <>

                <SkeletonBodyText />
                <SkeletonBodyText />
            </>}
        </Card>
    );
}