import { CircularProgress } from "@mui/material";
import React from "react";

export const LoadingBox = () => {
  return (
    <div className='loading'>
      <CircularProgress color='success' />
      <i
        className='fa fa-spinner fa-spin'
        style={{ marginLeft: "10px" }}></i>{" "}
      Booking creating...
    </div>
  );
};

export const LoadingPrintBox = () => {
  return (
    <div className='loading mb-3'>
      <div>
        <CircularProgress color='success' />
      </div>
      <i className='fa fa-spinner fa-spin' style={{ marginLeft: "10px" }}></i>{" "}
      Wait for printing...
    </div>
  );
};

export const NoRecordsFound = () => {
  return (
    <div className=''>
      <h3> No record found.</h3>
    </div>
  );
};
