import {
  SETTING_DATA_FAIL,
  SETTING_DATA_SUCCESS,
  SETTING_TAB_SUCCESS,
  SHOPINFO_DATA
} from "../actions/type";

export const settingReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOPINFO_DATA:
      return {
        ...state,
        loading: false,
        shopInfo: action.payload,
      };
    case SETTING_TAB_SUCCESS:
      return {
        ...state,
        loading: false,
        setting: action.payload,
      };

    case SETTING_DATA_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };

    case SETTING_DATA_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
