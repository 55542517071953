import {
  Card,
  ContextualSaveBar,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  Tabs,
  Toast
} from '@shopify/polaris';
import Orders from './components/Orders';
import { useCallback } from 'react';
import Setting from './components/SettingPage';

import React, {

  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import EligibleOrder from './components/EligibleOrder';
import NonEligibleOrder from './components/NonEligibleOrder';
import BookedOrder from './components/BookedOrder';
import { AppUIContext } from './context/AppUIContext';
import  axios  from 'axios';
import { API_ROOT } from './constant/config';
import { useSelector } from 'react-redux';



const Settings = () => {
  var tabs = [
    {
      id: 'configuration',
      content: 'Intelcom Configuration',
      panelID: 'configuration',
      isAccess: true
    },
  
    {
      id: 'all-orders',
      content: 'All Orders',
      panelID: 'all-orders',
      isAccess: true
    },
    {
      id: 'Eligible-orders',
      content: 'Eligible Orders',
      panelID: 'Eligible-orders',
      isAccess: true
    },
    {
      id: 'non-Eligible-orders',
      content: 'Non-Eligible Orders',
      panelID: 'non-Eligible-orders',
      isAccess: true
    },
    {
      id: 'booked-orders',
      content: 'Booked Orders',
      panelID: 'booked-orders',
      isAccess: true
    },
  ];
  const [tab, setTab] = useState(tabs);
  useEffect((): void => {
    getAccess()
    async function getAccess() {
      const response = await axios.get(`${API_ROOT}/setting?shop=${localStorage.getItem('seperatedShop')}`);
      if (!response.data.data.isValidate) {
        setTab([ {
          id: 'configuration',
          content: 'Intelcom Configuration',
          panelID: 'configuration',
          isAccess: true
        }])
      }
    }

  }, []);

  const settingDefaultdata = useSelector((state:any)=> state.settingReducer.setting)
  console.log(settingDefaultdata)
  
  const [selected, setSelected] = useState(settingDefaultdata?settingDefaultdata:0);
  
  const handleTabChange = useCallback(
    (selectedTabIndex: any) => setSelected(selectedTabIndex),
    []
  );




  return (
    <>
    
      <Tabs tabs={tab} selected={selected} onSelect={handleTabChange} 
    
      />
      {selected === 0 && <>

        <Setting />
      </>}
      {selected === 1 && <>
        <Orders />
      </>}
      {selected === 2 && <>
        <EligibleOrder />
      </>}
      {selected === 3 && <>
        <NonEligibleOrder />
      </>
      
      }
       {selected === 4 && <>

        <BookedOrder />
        
      </>
      }
     

      {/* {!state.settings && (
          <SkeletonPage>
            <Card sectioned={true}>
              <SkeletonBodyText />
            </Card>
            <Card sectioned={true}>
              <SkeletonBodyText />
            </Card>
          </SkeletonPage>
        )}
        {state.settings && (
          <Tabs
            tabs={tabs}
            selected={selected}
            onSelect={handleTabChange}
            fitted={true}
          >
            <div style={{ marginTop: '15px' }}>
              {selected === 0 && (
                <ProductPage
                  settings={state.settings}
                  onSettingChanged={updateSetting}
                />
              )}
  
              {selected === 1 && (
                <CollectionAndCartPage
                  settings={state.settings}
                  onSettingChanged={updateSetting}
                  isLoading={state.isLoading}
                  isDirty={state.isDirty}
                  getSettings={refersh}
                  saveSettings={triggerSave}
                  onError={() => dispatch({ type: ACTION.ERROR })}
                />
              )}
              {selected === 2 && (
                <StockLocationRules
                  settings={state.settings}
                  onSettingChanged={updateSetting}
                />
              )}
              {selected === 3 && <ProductLocationInfoTab />}
            </div>
          </Tabs>
        )}
        {state.showToast && (
          <Toast
            error={state.isErrored}
            content={
              state.isErrored
                ? 'Errors occured, please re-load the app and try again or contact support'
                : 'Settings saved'
            }
            onDismiss={() => dispatch({ type: ACTION.HIDE_TOAST })}
          />
        )} */}
    </>

  );
};

export default Settings;