import React, { useState, useCallback } from "react";

import {
  useIndexResourceState,
  Card,
  IndexTable,
  TextStyle,
  SkeletonBodyText,
  Button,
  Filters,
  Tag,
  Pagination,
} from "@shopify/polaris";
// import PropTypes from 'prop-types';


import axios from "axios";
import { TrackingDialog } from "./TrackingDialog";
import { LoadingPrintBox, NoRecordsFound } from "./LoadingBox";
import { API_ROOT } from "../constant/config.js"
import Fuse from "fuse.js";

export default function Orders() {

  const [orders, setOrders] = useState([]);
  const [packageString, setPackageString] = useState(null);
  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [printLoading, setPrintLoading] = useState(false);
  const [queryValue, setQueryValue] = useState('');
  const [submitted] = useState(null);
  const [locations, setLocations] = useState([]);
  const [queryData, setQuerydata] = useState([]);
  const [page, setPage] = useState(1)
  const [limit] = useState(10)


  function getLocation(id) {
    const location = locations.filter((item) => {
      return String(item.id) === String(id);
    })[0];

    return location?.name;
  }

  const fuse = new Fuse(orders,
    {
      keys: [
        'OrderId',
        'customerName',
      ]
    })

  const results = fuse.search(queryValue);
  const ordersResult = queryValue ? results.map((r) => r.item) : orders;

  // get shop
  const shop = localStorage.getItem("seperatedShop");

  // get orders
  React.useEffect(() => {
    try {
      setLoading(true);

      async function getOrders(shop) {
        console.log(queryValue)

        const orders = await axios.get(`${API_ROOT}/orders?shop=${shop}&page=${page}&limit=${limit}
        `
        );
        console.log(orders);
        setOrders(orders.data.data.docs);
        setQuerydata(orders.data.data)
        setLoading(false);
      }
      async function getLocations() {
        const shop = localStorage.getItem("seperatedShop");
        const locations = await axios.get(`${API_ROOT}/locations?shop=${shop}`);
        setLocations(locations.data.data);
      }
      getLocations();
      getOrders(shop);
    }
    catch (e) {
      console.log(e.response && e.response.message)
    }

  }, [shop, queryValue, submitted, page, limit]);


  // print order
  const handlePrint = async (orderId, trackId,id) => {
    try {
      setPrintLoading(true)
      const printResponse = await axios.get(`${API_ROOT}/print-lable?shop=${shop}&orderId=${orderId}&trackId=${trackId}&id=${id}`);
      setPrintLoading(false)
      window.open(printResponse.data.data.url, "_blank");
    }
    catch (e) {
      console.log(e.response && e.response.message)
    }

  };

  const [open, setOpen] = React.useState(false);

  const handleClose = (value) => {
    setOpen(false);
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(ordersResult);
  // get tracking modal
  const viewHistory = async (TrackingId) => {
    try {
      setOpen(true);
      const response = await axios.get(`${API_ROOT}/track?shop=${shop}&TrackingId=${TrackingId}`);
      setPackageString(response.data.data.status_history);
      console.log(response.data.data.status_history[4].status_code_string)

      //setTime(response.data.data.status_history[4].timestamp)
    }
    catch (e) {
      console.log(e.response && e.response.message)
    }

  };


  const rowMarkup =
    ordersResult &&
    ordersResult.map(
      (
        {
          _id,
          customerEmail,
          customerName,
          TotalAmount,
          OrderCreatedAt,
          OrderId,
          isEligable,
          BookingId,
          TrackingId,
          TrackingStatus,
          LocationId,
          isBooked
        },
        index
      ) => (
        <IndexTable.Row
          id={_id}
          key={_id}
          selected={selectedResources.includes(_id)}
          position={index}>
          <IndexTable.Cell>
            <TextStyle variation='strong'>{OrderId}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <TextStyle>
              {getLocation(LocationId)}
            </TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <TextStyle>{isEligable ? "Eligible" : "Non Eligible"}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>{OrderCreatedAt.split("T")[0]}</IndexTable.Cell>
          <IndexTable.Cell>{customerName}</IndexTable.Cell>
          <IndexTable.Cell>{customerEmail}</IndexTable.Cell>
          <IndexTable.Cell>{formatter.format(TotalAmount)}</IndexTable.Cell>
          <IndexTable.Cell>
            {isBooked ? <span>Booked</span> : <span>Pending</span>}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <>
              {BookingId ? <><Tag>{BookingId}</Tag></> : null}
            </>
          </IndexTable.Cell>

          <IndexTable.Cell>
            {" "}
            <>
              {TrackingId ? <><Tag>{TrackingId}</Tag></> : null}
            </>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <>
              {TrackingStatus ? <><Tag>{TrackingStatus}</Tag></> : null}
            </>
          </IndexTable.Cell>
          {isBooked ? (
            <IndexTable.Cell>
              <div data-primary-link>
                <Button
                  // loading={printLoading}
                  onClick={() => handlePrint(OrderId, TrackingId,_id)}>
                  Lable
                </Button>&nbsp;&nbsp;&nbsp;
                <Button primary onClick={() => viewHistory(TrackingId)}>
                  Track
                </Button>
              </div>
            </IndexTable.Cell>
          ) : (<IndexTable.Cell>
            <div data-primary-link>
            </div>
          </IndexTable.Cell>)}
        </IndexTable.Row>
      )
    );



  const [accountStatus, setAccountStatus] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(null),
    [],
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);




  const appliedFilters = [];
  if (!isEmpty(accountStatus)) {
    const key = 'accountStatus';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  return (
    <Card sectioned>
      {printLoading && <LoadingPrintBox />}
      <div style={{ marginBottom: "20px" }}>
        <Filters
          queryValue={queryValue}
          filters={[]}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
        /></div>
      {!loading ? <>
        <TrackingDialog
          large
          open={open}
          packageString={packageString}
          time={time}
          onClose={handleClose}
        />
        {rowMarkup.length === 0 ?
          <NoRecordsFound />

          : <><IndexTable
            resourceName={resourceName}
            itemCount={orders && orders.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            selectable={false}
            headings={[
              { title: "Shopify Order Id" },
              { title: "Origin" },
              { title: "Order Eligibility" },
              { title: "Shopify Order Date" },
              { title: "Customer Name" },
              { title: "Customer Email" },
              { title: "Order Amount" },
              { title: "Booking Status" },
              { title: "Booking Id" },
              { title: "Tracking Id" },
              { title: "Tracking Status" },
              { title: "Print/Tracking" },

            ]} lastColumnSticky>
            {rowMarkup}
          </IndexTable>
          
          </>
        }
          <div style={{ marginTop: "20px" }}>
              <Pagination
                hasPrevious = {queryData.hasPrevPage}
                onPrevious={async () => {
                  setPage(page - 1)
                }}
                hasNext = {queryData.hasNextPage}
                onNext={async () => {
                  setPage(page + 1)
                }}
              />
            </div>
      </> : <>



        <SkeletonBodyText />
        <SkeletonBodyText />
      </>}
    </Card>

  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      case 'accountStatus':
        return value.map((val) => `Filtered With ${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}