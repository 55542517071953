import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Page,
  IndexTable,
  TextStyle,
  Frame,
  Toast,
  TextField,
  Select,
} from "@shopify/polaris";
import { API_ROOT } from "../constant/config.js";


import axios from "axios";
import Skeletons from "./Skeletons";
import { LoadingBox } from "./LoadingBox.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSettingData, getSettingTabData ,getShopData} from "../actions/setting.js";
import  _ from "underscore"

export default function Orders() {
  let navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(false);
  const [message, setToast] = useState(false);
  const [loading, setLoader] = useState(false);
  const [loadingdata, setLoadingData] = useState(false);
  const [locations, setLocations] = useState([]);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [length, setLength] = useState({ values: [] });
  const [width, setWidth] = useState({ values: [] });
  const [height, setHeight] = useState({ values: [] });

  console.log(length,"length")
  // dimensions

  const handleChangeLenght = (i, event) => {
    setLength({
      values: { ...length.values, [i]: event }
    });
  }
  const handleChangeWidth = (i, event) => {
    setWidth({
      values: { ...width.values, [i]: event }
    });
  }
  const handleChangeHeight = (i, event) => {
    setHeight({
      values: { ...height.values, [i]: event }
    });
  }
  const [selected, setSelected] = useState({ values: [] });


  const handleSelectChange = (i, event) => {
    console.log(event)
    setSelected({
      values: { ...selected.values, [i]: event }
    });
  }



  // const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "cm", value: "cm" },
    { label: "inches", value: "in" },
  ];

  // second Dimension

  const [selectValue, setSelectValue] = useState({ values: [] });

  const handleSelectChangeWeight = (i, event) => {
    setSelectValue({
      values: { ...selectValue.values, [i]: event }
    });
  }

  const [weight, setWeight] = useState({ values: [] });

  const handleWeightFieldChange = (i, event) => {
    setWeight({
      values: { ...weight.values, [i]: event }
    });
  }



  const toastMarkup = active ? (
    <Toast content={message} onDismiss={toggleActive} />
  ) : null;

  const shop = localStorage.getItem("seperatedShop");

  const { settings } = useSelector(
    (state) => ({
      settings: state.settingReducer && state.settingReducer.settings,
    }),
    shallowEqual
  );
  const { shopInfo } = useSelector(
    (state) => ({
      shopInfo: state.settingReducer && state.settingReducer.shopInfo,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function getSettings() {
      await dispatch(getSettingData());
      await dispatch(getShopData());

    }
    getSettings();
  }, [dispatch]);

  useEffect(() => {
    let locationIds =  localStorage.getItem("locationIds");
    locationIds = JSON.parse(locationIds)
    try {
      async function getItems() {
        setLoadingData(true);
        const filteredData = localStorage.getItem("booking");
        const values = JSON.parse(filteredData, (key, value) => {
          return `${value}`;
        });
        var originBasedonIds = await axios.get(
          `${API_ROOT}/items?shop=${shop}&&ids=${values}`
        );
     
        locationIds = _.uniq(locationIds)
        console.log(locationIds)
        let result = locationIds.map(element => {
         console.log(element)
         return originBasedonIds.data.data.map(t=>{
               return  _.groupBy(t[element],'orderId')
             })
         });
        result = _.flatten(result,2)
        console.log(result)
        result = result.map((data)=>{
          const object =  Object.keys(data).map((key1,index)=>{
              return {
                  [key1]:data[key1]
              } 
          })
          return object   
      })
      const ObjectArray = []
      result.map((data)=>{
          data.map((data1)=>{
              ObjectArray.push(data1)
          })
      })
    
       setItems(ObjectArray)
        // setItems(originBasedonIds.data.data);
        setLoadingData(false);
      }

      async function getLocations() {
        const shop = localStorage.getItem("seperatedShop");
        const locations = await axios.get(`${API_ROOT}/locations?shop=${shop}`);
        setLocations(locations.data.data);
      }
      getItems();
      getLocations();
    

     let l={
      values:{

      }
     };
     let w={
      values:{
        
      }
     }
     let h={
      values:{
        
      }
    }
     let we={
      values:{
        
      }
    };
     for (let index = 0; index < locationIds.length; index++) {
      console.log(index)
      l.values ={
        ...l.values,
        [index]:"1"
      }
      h.values ={
        ...h.values,
        [index]:"1"
      }
      w.values ={
        ...w.values,
        [index]:"1"
      }
      we.values ={
        ...we.values,
        [index]:"1"
      }      
     }
     console.log("Length",l)
     setLength(l)
     setHeight(h)
     setWeight(we)
     setWidth(w)

    } catch (e) {
      console.log(e.response && e.response.message);
    }
  }, [shop]);

  // get location id
  function GetLocationId(name) {
    const location = locations.filter((item) => {
      return String(item.name) === String(name);
    })[0];
    return location && location.id;
  }

  // got from https://www.codegrepper.com/code-examples/javascript/javascript+get+random+a-z+symbol
  const getTrackingId = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text.toUpperCase();
  };


  //create booking
  const createBooing = async (item, index) => {
    
    console.log("Cliecked .....")
    setLoader(true);
    const ids = item.map((item) => {
      return item.props.children.props.children[0].props.children.props
        .children;
    });
    // uniq
    const orderId = [...new Set(ids)];
    const orderProductId = item[0].props.children.props.children[0].props.children.props.children

    //get products
    let products = items[index][orderProductId];
    console.log(products,items,index,)
    products = products.map((product) => {
      if (product.customer === undefined) {
        setLoader(false);
        setActive(!active);
        setToast("you cant book because the customer is undefined");
        return;
      }
      return {
        sku: product.sku,
        description: product.description,
        country_of_origin: product.country_of_origin,
        price: product.price,
        extended_price: product.extended_price,
        quantity: product.quantity,
        weight: product.weight,
        orderId: product.orderId,
        customer: product.customer,
      };
    });
    // get items
    orderId.map(async (id) => {
      const items = products.filter((item) => {

        return item.orderId === id;
      });

      //currunt customer
      const customer = items[0].customer;


      // store customer
      localStorage.setItem("customerData", JSON.stringify(customer));

      // get customer
      const getCustomer = JSON.parse(localStorage.getItem("customerData"));
      // remove orderId and customer
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        delete item.orderId;
        delete item.customer;
      });
     const traking_id = `${settings.PRIFIX}${getTrackingId()}`
      //send body payload
      const body = {
        client_code: settings.API_SECRET,
        client_booking_number: "TESTBOOKING1",
        shipments: [
          {
            manifest_type: "shipment_manifest",
            shipper: {
              address_type: "business",
              address: {
                name: shopInfo.name,
                address_line1: shopInfo.address1,
                city: shopInfo.city,
                state_province: shopInfo.province,
                zip: shopInfo.zip,
                country_code: shopInfo.province_code,
              },
              contact_phone:  shopInfo.phone ? shopInfo.phone : null,
              contact_email: shopInfo.email,
            },
            tracking_ID: traking_id,
            client_manifest_number: "TESTBOOKING1",
            ship_from_legs: [
              {
                address: {
                  name: shopInfo.name,
                  address_line1: shopInfo.address1,
                  city: shopInfo.city,
                  state_province: shopInfo.province,
                  zip: shopInfo.zip,
                  country_code: shopInfo.country,
                },
                contact_name: shopInfo.shop_owner,
                contact_email: shopInfo.email,
                contact_phone: shopInfo.phone,
              },
            ],
            pieces: 1,
            packages: [
              {
                manifest_type: "package_manifest",
                shipper: {
                  address_type: "business",
                  address: {
                    name: shopInfo.name,
                    address_line1: shopInfo.address1,
                    city: shopInfo.city,
                    state_province: shopInfo.province,
                    zip: shopInfo.zip,
                    country_code: shopInfo.country,
                  },
                  contact_email: shopInfo.email,
                  contact_phone: shopInfo.phone,
                },
                actual_weight: {
                  unit_of_measure: selectValue.values[0] || "kg",
                  value: parseInt(weight.values[0] || 1),
                },
                dimensions: {
                  length: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(length.values[0] || 1),
                  },
                  height: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(height.values[0] || 1),
                  },
                  width: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(width.values[0] || 1)
                  },
                },
                tracking_ID: traking_id,
                client_package_ID: "TESTBOOKING1",
                consignee_address: {
                  address_type: "home",
                  address: {
                    name: getCustomer.default_address.name,
                    address_line1: getCustomer.default_address.address1,
                    city: getCustomer.default_address.city,
                    state_province: getCustomer.default_address.province,
                    zip: getCustomer.default_address.zip,
                    country_code: getCustomer.default_address.province_code,
                  },
                  contact_phone: getCustomer.phone,
                  contact_email:getCustomer.email
                },
                tasks: [
                  {
                    task_type: "parcel",
                    service_type: "standard",
                    proof_of_execution: "photo",
                  },
                ],
                // products: items,
              },
            ],
          },
        ],
      };
      console.log(body)
      // get shop from state
      const shop = localStorage.getItem("seperatedShop");

      // create booking

        try {
          var res;
          body.client_booking_number = id
          body.shipments[0].client_manifest_number = id
          body.shipments[0].packages[0].client_package_ID = id
          setLoader(true);
          res = await axios.post(
            `${API_ROOT}/booking?shop=${shop}&orderId=${id}`,
            body
          ).then((data) => {
            setLoader(false);
            dispatch(getSettingTabData(2));
            setActive(!active);
            setToast("Booking creation successfully");
            setTimeout(() => {
              navigate(`/?shop=${shop}`);
            }, 1000)
          })
        }
        catch (e) {
          setLoader(false);
          setActive(!active);
          console.log(e)
          await setToast(e.response && e.response.data && e.response.data.message);
        }
    });
    // setLoader(false);
  };
   const createBooingV2 = async (item, index) => {
    try {
      setLoader(true);
    var orderId1=  localStorage.getItem("booking")
    const orderId = JSON.parse(orderId1)
    orderId.map(async (id,index) => {
      //currunt customer
      const customer = items[index][id][0].customer;
      // store customer
      localStorage.setItem("customerData", JSON.stringify(customer));

      // get customer
      const getCustomer = JSON.parse(localStorage.getItem("customerData"));
      // remove orderId and customer
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        delete item.orderId;
        delete item.customer;
      });
     const traking_id = `${settings.PRIFIX}${getTrackingId()}`
      //send body payload
      const body = {
        client_code: settings.API_SECRET,
        client_booking_number: "TESTBOOKING1",
        shipments: [
          {
            manifest_type: "shipment_manifest",
            shipper: {
              address_type: "business",
              address: {
                name: shopInfo.name,
                address_line1: shopInfo.address1,
                city: shopInfo.city,
                state_province: shopInfo.province,
                zip: shopInfo.zip,
                country_code: shopInfo.province_code,
              },
              contact_phone:  shopInfo.phone ? shopInfo.phone : null,
              contact_email: shopInfo.email,
            },
            tracking_ID: traking_id,
            client_manifest_number: "TESTBOOKING1",
            ship_from_legs: [
              {
                address: {
                  name: shopInfo.name,
                  address_line1: shopInfo.address1,
                  city: shopInfo.city,
                  state_province: shopInfo.province,
                  zip: shopInfo.zip,
                  country_code: shopInfo.country,
                },
                contact_name: shopInfo.shop_owner,
                contact_email: shopInfo.email,
                contact_phone: shopInfo.phone,
              },
            ],
            pieces: items[index][id].length,
            packages: [
              {
                manifest_type: "package_manifest",
                shipper: {
                  address_type: "business",
                  address: {
                    name: shopInfo.name,
                    address_line1: shopInfo.address1,
                    city: shopInfo.city,
                    state_province: shopInfo.province,
                    zip: shopInfo.zip,
                    country_code: shopInfo.country,
                  },
                  contact_email: shopInfo.email,
                  contact_phone: shopInfo.phone,
                },
                actual_weight: {
                  unit_of_measure: selectValue.values[0] || "kg",
                  value: parseInt(weight.values[0] || 1),
                },
                dimensions: {
                  length: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(length.values[0] || 1),
                  },
                  height: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(height.values[0] || 1),
                  },
                  width: {
                    unit_of_measure: selected.values[0] || "cm",
                    value: parseInt(width.values[0] || 1)
                  },
                },
                tracking_ID: traking_id,
                client_package_ID: "TESTBOOKING1",
                consignee_address: {
                  address_type: "home",
                  address: {
                    name: getCustomer.default_address.name,
                    address_line1: getCustomer.default_address.address1,
                    city: getCustomer.default_address.city,
                    state_province: getCustomer.default_address.province,
                    zip: getCustomer.default_address.zip,
                    country_code: getCustomer.default_address.province_code,
                  },
                  contact_phone: getCustomer.phone,
                  contact_email:getCustomer.email
                },
                tasks: [
                  {
                    task_type: "parcel",
                    service_type: "standard",
                    proof_of_execution: "photo",
                  },
                ],
                // products: items,
              },
            ],
          },
        ],
      };
      console.log(body)
      // get shop from state
      const shop = localStorage.getItem("seperatedShop");
      var res;
      body.client_booking_number = id
      body.shipments[0].client_manifest_number = id
      body.shipments[0].packages[0].client_package_ID = id
      setLoader(true);
      res = await axios.post(
        `${API_ROOT}/booking?shop=${shop}&orderId=${id}`,
        body
      ).then((data) => {
        setLoader(false);
        dispatch(getSettingTabData(2));
        setActive(!active);
        setToast("Booking creation successfully");
        setTimeout(() => {
          navigate(`/?shop=${shop}`);
        }, 1000)
      })
    });
    } catch (e) {
      console.log(e)
      setLoader(false);
      setActive(!active);
      console.log(e)
      await setToast(e.response && e.response.data && e.response.data.message);
    }
    
    // setLoader(false);
  };

  // get location id
  function getLocation(id) {
    const location = locations.filter((item) => {
      return String(item.id) === String(id);
    })[0];

    return location && location.name;
  }

  const goBack = () => {
    navigate("/");
    dispatch(getSettingTabData(2));
  };
  console.log(items)
  const rowMarkup =
    items &&
    items.map((item1, index) =>
      Object.keys(item1).map((key, index) =>
        item1[key].map((item, index) => (
          <>
            <IndexTable.Row id={item._id} key={item._id} position={index}>
              <IndexTable.Cell>
                <TextStyle variation='strong'>{item.orderId}</TextStyle>
              </IndexTable.Cell>
              <IndexTable.Cell>{item.title}</IndexTable.Cell>
              <IndexTable.Cell>{getLocation(item.location_id)}</IndexTable.Cell>
              <IndexTable.Cell>{item.id}</IndexTable.Cell>
              <IndexTable.Cell>{item.ShopifyorderId}</IndexTable.Cell>
            </IndexTable.Row>
          </>
        ))
      )
    );

  return (
    <>
      <>
                  <div
                    className='mt-5'
                    style={{
                      float: "right",
                      marginTop: "20px",
                      marginRight: "12px"
                    }}
                  >
                    <Button loading={false} onClick={goBack}>
                      Back
                    </Button>{" "}
                    <Button
                      primary
                      loading={loading}

                      // loading={loading}
                      onClick={() => {
                        createBooingV2(items);
                      }}>
                      Create Booking
                    </Button>
                  </div>
                </>
      {!loadingdata ? (
        <>
          <Frame>
        
            {/* {loading && <LoadingBox />} */}
            {toastMarkup}

            {rowMarkup &&
              rowMarkup.map((item, index) => (
                <>
                  <Page fullWidth>
                    <Card style={{ margin: "30px 0" }}>
                      <br />
                      <br />
                      <IndexTable
                        itemCount={item.length}
                        selectable={false}
                        headings={[
                          { title: "Order Id" },
                          { title: "Name" },
                          { title: "Origin" },
                          { title: "Product Id" },
                          { title: "Shopify Order Id" },
                        ]}>
                        {item}
                      </IndexTable>


                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                        }}>
                        <div
                          style={{ display: "flex", width: "30%" }}
                          className='dimesion_one'>
                          <TextField
                            label='Length'
                            type='number'
                            value={length.values[index]}
                            name={length.values[index]}
                            onChange={(event) => handleChangeLenght(index, event)}
                            autoComplete='off'
                          />
                          <TextField
                            label='Width'
                            type='number'
                            value={width.values[index]}
                            name={width.values[index]}
                            onChange={(event) => handleChangeWidth(index, event)}
                            autoComplete='off'
                          />
                          <TextField
                            label='Height'
                            type='number'
                            value={height.values[index]}
                            name={height.values[index]}
                            onChange={(event) => handleChangeHeight(index, event)}
                            autoComplete='off'
                          />
                          <div className='unique_field'>
                            <Select
                              label='c'
                              options={options}
                              value={selected.values[index] || "cm"}
                              name={selected.values[index]}
                              onChange={(event) => handleSelectChange(index, event)}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "16%",
                            marginLeft: "30px",
                            marginRight: "30px",
                          }}>
                          <TextField
                            label='Weight'
                            type='number'
                            // value={weight}
                            // onChange={handleTextFieldChange}
                            value={weight.values[index]}
                            name={weight.values[index]}
                            onChange={(event) => handleWeightFieldChange(index, event)}
                            autoComplete='off'
                            connectedRight={
                              <Select

                                label='Weight unit'
                                labelHidden
                                options={["kg", "lb"]}
                                value={selectValue.values[index] || "kg"}
                                name={selectValue.values[index]}
                                onChange={(event) => handleSelectChangeWeight(index, event)}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div
                        className='mt-5'
                        style={{
                          float: "right",
                          marginTop: "20px",
                          marginRight: "12px",
                        }}>
                      </div>

                      <br />
                      <br />

                      <br />
                      <br />
                    </Card>
                  </Page>
                </>
              ))}
                <>
                  <div
                    className='mt-5'
                    style={{
                      float: "right",
                      marginTop: "20px",
                      marginRight: "12px"
                    }}
                  >
                    <Button loading={false} onClick={goBack}>
                      Back
                    </Button>{" "}
                    <Button
                      primary
                      loading={loading}
                      onClick={() => {
                        createBooingV2(items);
                      }}>
                      Create Booking
                    </Button>
                  </div>
                </>
          </Frame>
        </>
      ) : (
        <Skeletons />
      )}
    </>
  );
}
