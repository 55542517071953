import React from 'react';
import { Page, Button,Card, TextStyle } from '@shopify/polaris';


export default function UnAuthorizePage() {
    
    return (
        <>
        <Page>
            <Card sectioned>
                
                <TextStyle variation="strong" >
                    You are not authorized to App this store.
                    Please contact your administrator.
                </TextStyle>
              
            </Card>
            
        </Page>


            
        </>
    );
}