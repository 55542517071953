
import thunk from 'redux-thunk'
import { applyMiddleware, createStore, compose, combineReducers } from "redux"
import { settingReducer } from "./reducer/setting"


const initialState = {
    setting:0
}
const reducer = combineReducers({
    settingReducer: settingReducer,
    

})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)))

export default store;
