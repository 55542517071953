import axios from "axios";
import { API_ROOT } from "../constant/config";
import { SETTING_DATA_FAIL, SETTING_DATA_REQUEST, SETTING_DATA_SUCCESS, SETTING_TAB_SUCCESS ,SHOPINFO_DATA} from "./type"
export const getSettingTabData = (data)=> async(dispatch)=>
{
    // try{
        dispatch({type:SETTING_TAB_SUCCESS,payload:data})
    // }
    // catch(error)
    // {
    //     dispatch({type:PRODUCT_LIST_FAIL,payload:error.message})
    // }
}

export const getSettingData = ()=> async(dispatch)=>
{
  const shop = localStorage.getItem("seperatedShop");

    try{
        const response = await axios.get(`${API_ROOT}/setting?shop=${shop}`);
        dispatch({type:SETTING_DATA_SUCCESS,payload:response && response.data && response.data.data})
    }
    catch(error)
    {
        dispatch({type:SETTING_DATA_FAIL,payload:error.message})
    }
}

export const getShopData = ()=> async(dispatch)=>
{
  const shop = localStorage.getItem("seperatedShop");

    try{
        const response = await axios.get(`${API_ROOT}/shop?shop=${shop}`);
        dispatch({type:SHOPINFO_DATA,payload:response && response.data && response.data.data})
    }
    catch(error)
    {
        dispatch({type:SETTING_DATA_FAIL,payload:error.message})
    }
}