import React, { useState, useCallback, useEffect } from "react";
import {
  Page,
  Card,
  FormLayout,
  TextField,
  Form,
  Button,
  Toast,
  Frame,
  InlineError
} from "@shopify/polaris";
import axios from "axios";
import '@shopify/polaris/build/esm/styles.css';
import { API_ROOT } from "../constant/config.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSettingData } from "../actions/setting.js";


export default function Setting() {
  const [settingData, setSettingData] = useState(null);
  const [url, setUrl] = useState("");
  const [code, setCode] = useState("");
  const [prefix, setPrefix] = useState("");
  const [active, setActive] = useState(false);
  const [message, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [flag, setFlag] = useState(false)


  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={message} onDismiss={toggleActive} />
  ) : null;

  // get shop
  const shop = localStorage.getItem("seperatedShop");

  const { settings } = useSelector(
    (state) => ({
      settings:
        state.settingReducer && state.settingReducer.settings,
    }),
    shallowEqual
  );

  console.log(settings)
  const dispatch = useDispatch();


  useEffect(() => {
    setUrl(settings && settings.API_KEY)
    setCode(settings && settings.API_SECRET)
    setPrefix(settings && settings.PRIFIX)

  }, [dispatch, settings])


  useEffect(() => {

    async function getSettings() {
      await dispatch(getSettingData());

    }
    getSettings()
  }, [dispatch])

  const handleSubmit = async (e) => {
    try {

      setLoading(true)

      await axios.put(`${API_ROOT}/setting?shop=${shop}`, {
        API_KEY: url && url,
        API_SECRET:code && code,
        PRIFIX: prefix && prefix.toUpperCase(),

      });
      setLoading(false)
      setActive(!active);
      setToast("Setting Updated Successfully...");
    }
    catch (e) {
      setLoading(false)
      setActive(!active);
      setToast(e.response && e.response.data && e.response.data.message);
    }
  }
  const handleApiKeyChange = useCallback((value) => setUrl(value), []);
  const handleClientCodeChange = useCallback((value) => setCode(value), []);
  const handleClientPrefixChange = useCallback((value) => {
    setPrefix(value)

    console.log(value && value)
    if ((value && value.length < 2) || value && value.length > 8) {
      setFlag(true)
      setError({
        prefix: "Prefix should be between 2-8 characters only..."
      });
    }
    else {
      setFlag(false)
      setError({
        prefix: ""
      });
    }
  }, []);




  return (
    <Frame>
      <Page fullWidth>
        {toastMarkup}
        <Card sectioned>
          <Form noValidate
            onSubmit={handleSubmit}
          >
            <FormLayout>
              <TextField
                value={url}
                onChange={handleApiKeyChange}
                label='API Key'
                type='text'
                autoComplete='off'
              />
              <TextField
                value={code}
                onChange={handleClientCodeChange}
                label='Client Code'
                type='text'
                autoComplete='off'
              />

              <TextField
                value={prefix}
                onChange={handleClientPrefixChange}
                label='App Prefix'
                type='text'
                autoComplete='off'
              />
              <InlineError message={error.prefix} fieldID="myFieldID" />
              <Button
                loading={loading}
                disabled={flag}
                submit primary >
                Update
              </Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    </Frame>
  );
}